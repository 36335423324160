<template>
    <div class="pannello map-layout">

        <div class="moving-tooltip" :style="moving">
            <h2>{{ hoverMembers }}</h2>
        </div>

        <div class="map" ref="map"></div>

        <div class="button-grid desktop" :class="[this.showButtons ? 'fixed' : 'hidden']">
            <div @click="toggleButtons()" :class="['icon-right', this.showButtons ? 'read-more upside' : 'read-more']">
                <p>Filter by Working Group</p>
            </div>

            <div v-if="this.selectedWG!=0" class="selected-wg icon-right close" @click="clearWG()">
                {{ this.wgTitle }}
            </div>

            <button @click="selectWG(1)" :class="{
                    'active' : this.selectedWG==1, 'btn border': true, 
                    'contains': this.whereIsCountry.includes(1) }">
                <div class="wg-icon target14"></div>
                <span>School education and Public Outreach</span>
            </button>

            <button @click="selectWG(2)" :class="{
                    'active' : this.selectedWG==2, 'btn border': true, 
                    'contains': this.whereIsCountry.includes(2) }">
                <div class="wg-icon target15"></div>
                <span>Higher Education</span>
            </button>

            <button @click="selectWG(3)" :class="{
                    'active' : this.selectedWG==3, 'btn border': true, 
                    'contains': this.whereIsCountry.includes(3) }">
                <div class="wg-icon target16"></div>
                <span>Lifelong Learning and (re)training</span>
            </button>

            <button @click="selectWG(4)" :class="{
                    'active' : this.selectedWG==4, 'btn border': true, 
                    'contains': this.whereIsCountry.includes(4) }">
                <div class="wg-icon target17"></div>
                <span>Transversal Education</span>
            </button>

            <button @click="selectWG(5)" :class="{
                    'active' : this.selectedWG==5, 'btn border': true, 
                    'contains': this.whereIsCountry.includes(5) }">
                <div class="wg-icon target18"></div>
                <span>Equity and inclusion</span>
            </button>

        </div>

        <div class="container-disclaimer">
            <p class="members-teaser teaser pink" v-if="this.selectedCountry!='' || this.selectedWG!=''">
                Please reset active filters to view all data
            </p>
        </div>

        <div class="map-info">

            <div class="wg-map-info">

                <div class="box first">
                    <div class="tot-members">

                        <!-- se filtro WG -->
                        <p class="num" v-if="this.selectedWG!=0 &&this.selectedMembers==0">
                            {{ totalMembers.tot }}
                        </p>
                        <!-- se seleziono country -->
                        <p class="num" v-if="this.selectedMembers!=0">{{ this.selectedMembers }}</p>
                        <!-- se non seleziono niente -->
                        <p class="num"
                            v-if="this.selectedWG ==0 && this.selectedMembers==0 || this.selectedMembers==null">
                            {{ this.totMembers }}
                        </p>

                        <p v-if="this.selectedCode=='country-total' || this.selectedCode=='country-wg'"
                            class="didascalia">
                            Member/s in
                            <br> <span class="country">{{ this.selectedName }}</span>
                        </p>

                        <p v-else class="didascalia">
                            <span class="country">{{ this.selectedName }}</span>
                            <br> Members
                        </p>
                    </div>
                    <div class="countries" v-if="selectedCode!='country-wg' && selectedCode!='country-total'">
                        <p class="num">{{ countryKeys.length }}</p>
                        <span class="total-label">Total</span>
                        <p class="didascalia">Countries</p>
                    </div>
                </div>

                <div class="box second" v-if="this.mapData && this.selectedCode!=''">
                    <div class="">
                        <p class="icon-left exp1">{{ 
                            (this.selectedCode=='total' || this.selectedCode=='country-total') ?
                            this.mapData.all_workgroups.members.experience_1 :
                            this.mapData.workgroup.members.experience_1 }}</p>
                        <p>Early career</p>
                    </div>
                    <div class="">
                        <p class="icon-left exp2">{{ 
                            (this.selectedCode=='total' || this.selectedCode=='country-total') ?
                            this.mapData.all_workgroups.members.experience_2 :
                            this.mapData.workgroup.members.experience_2 }}</p>
                        <p>Mid career</p>
                    </div>
                    <div class="">
                        <p class="icon-left exp3">{{ 
                            (this.selectedCode=='total' || this.selectedCode=='country-total') ?
                            this.mapData.all_workgroups.members.experience_3 :
                            this.mapData.workgroup.members.experience_3 }}</p>
                        <p>Advanced career</p>
                    </div>
                </div>

                <div class="box third">
                    <p>3 most popular categories of roles:</p>
                </div>

                <div class="box fourth" v-if="this.mapData && this.selectedCode!=''">
                    <div class="">
                        <p>{{ 
                            (this.selectedCode=='total' || this.selectedCode=='country-total') ?
                            max3(this.mapData.all_workgroups.members_roles)[0][1] :
                            max3(this.mapData.workgroup.members_roles)[0][1] }}</p>
                        <p>{{ 
                            (this.selectedCode=='total' || this.selectedCode=='country-total') ?
                            max3(this.mapData.all_workgroups.members_roles)[0][0] :
                            max3(this.mapData.workgroup.members_roles)[0][0] }}s</p>
                    </div>
                    <div class="">
                        <p>{{ 
                            (this.selectedCode=='total' || this.selectedCode=='country-total') ?
                            max3(this.mapData.all_workgroups.members_roles)[1][1] :
                            max3(this.mapData.workgroup.members_roles)[1][1] }}</p>
                        <p>{{ 
                            (this.selectedCode=='total' || this.selectedCode=='country-total') ?
                            max3(this.mapData.all_workgroups.members_roles)[1][0] :
                            max3(this.mapData.workgroup.members_roles)[1][0] }}s</p>
                    </div>
                    <div class="">
                        <p>{{ 
                            (this.selectedCode=='total' || this.selectedCode=='country-total') ?
                            max3(this.mapData.all_workgroups.members_roles)[2][1] :
                            max3(this.mapData.workgroup.members_roles)[2][1] }}</p>
                        <p>{{ 
                            (this.selectedCode=='total' || this.selectedCode=='country-total') ?
                            max3(this.mapData.all_workgroups.members_roles)[2][0] :
                            max3(this.mapData.workgroup.members_roles)[2][0] }}s</p>
                    </div>
                </div>

                <div class="box fifth" v-if="selectedCode!='total'">
                    <router-link class="btn slim plain" :to="'/working-group' + wglink"><span>View Working Group</span>
                    </router-link>
                </div>

            </div>

        </div>

        <div class="map-country-baloon">
            <div class="title">
                <h1 class="pink">QT Education</h1>
                <h1 class="underlined">European Community</h1>
            </div>




        </div>

        <div class="legend-grid">
            <div class="legend-line">
                <p>Number of members in countries:</p>
                <div class="bar"></div>
            </div>
        </div>

        <all-members></all-members>

    </div>
</template>

<script>
    import allMembers from '../components/layout-blocks/AllMembers.vue'
    import mapboxgl from 'mapbox-gl'
    mapboxgl.accessToken =
        'pk.eyJ1IjoiaW50ZXJmYXNlIiwiYSI6ImNqYW8zZGJkeTRjNXQzNHIxYmJpMWF6MnkifQ.TfWAUiqo6rDYVGid30Q6sA'
    import 'mapbox-gl/dist/mapbox-gl.css'
    import {
        fetchNodes
    } from '../../src/libs/drupalClient'
    export default {
        name: 'mapbox',
        components: {
            allMembers
        },
        data() {
            return {
                map: null,
                mapData: null,
                selectedCode: '',
                selectedISO3: '',
                selectedName: 'Total',
                selectedMembers: 0,
                selectedWG: 0,
                selectedCountry: '',
                showButtons: false,
                loadedWG: {
                    all: null,
                    wg1: null,
                    wg2: null,
                    wg3: null,
                    wg4: null,
                    wg5: null
                },
                countryKeys: [],
                //darkMapColor: '#022140',
                //baseMapColor: '#0a2e52',
                darkMapColor: '#071150',
                baseMapColor: '#0f1b66',
                lightMapColor: 'rgb(220, 220, 220)',
                hoverMapColor: 'rgb(250, 250, 250)',
                tooltip_top: 0,
                tooltip_left: 0,
                hoverMembers: 0,
                displayHover: false,
                totMembers: '',
            };
        },
        computed: {
            moving() {
                return "pointer-events: none; z-index: 11; color: #fff; position: absolute; " +
                    "top: " + this.tooltip_top + "px; " +
                    "left: " + this.tooltip_left + "px; " +
                    "opacity: " + (this.displayHover ? "1" : "0") + ";"
            },
            route() {
                return this.$route
            },
            totalMembers() {
                if (this.mapData) {
                    if (this.mapData.all_workgroups && this.selectedWG == 0) {
                        if (this.selectedCode == 'total')
                            return this.mapData.all_workgroups.members
                        else {
                            return {
                                tot: this.selectedMembers,
                                experience_1: this.mapData.all_workgroups.members.experience_1,
                                experience_2: this.mapData.all_workgroups.members.experience_2,
                                experience_3: this.mapData.all_workgroups.members.experience_3
                            }
                        }

                    } else if (this.mapData.workgroup) {
                        if (this.selectedCode == 'wg')
                            return this.mapData.workgroup.members
                        else {
                            return {
                                tot: this.selectedMembers,
                                experience_1: this.mapData.workgroup.members.experience_1,
                                experience_2: this.mapData.workgroup.members.experience_2,
                                experience_3: this.mapData.workgroup.members.experience_3
                            }
                        }
                    } else return {}
                } else
                    return {}
            },
            whereIsCountry() {
                var wgs = []

                //console.log(this.selectedCode, this.selectedISO3)
                var kks = []
                if ((this.selectedCode == 'country-total' || this.selectedCode == 'country-wg') && this.selectedISO3 !=
                    '') {
                    if (this.loadedWG.wg1) {
                        kks = Object.entries(this.loadedWG.wg1.workgroup.country)
                        kks.forEach(k => {
                            if (k[0] == this.selectedISO3)
                                wgs.push(1)
                        })
                    }
                    if (this.loadedWG.wg2) {
                        kks = Object.entries(this.loadedWG.wg2.workgroup.country)
                        kks.forEach(k => {
                            if (k[0] == this.selectedISO3)
                                wgs.push(2)
                        })
                    }
                    if (this.loadedWG.wg3) {
                        kks = Object.entries(this.loadedWG.wg3.workgroup.country)
                        kks.forEach(k => {
                            if (k[0] == this.selectedISO3)
                                wgs.push(3)
                        })
                    }
                    if (this.loadedWG.wg4) {
                        kks = Object.entries(this.loadedWG.wg4.workgroup.country)
                        kks.forEach(k => {
                            if (k[0] == this.selectedISO3)
                                wgs.push(4)
                        })
                    }
                    if (this.loadedWG.wg5) {
                        kks = Object.entries(this.loadedWG.wg5.workgroup.country)
                        kks.forEach(k => {
                            if (k[0] == this.selectedISO3)
                                wgs.push(5)
                        })
                    }
                }

                //console.log(wgs)
                return wgs
            },
            wglink() {
                switch (this.selectedWG) {
                    case 1:
                        return "/school-education-and-public-outreach"
                    case 2:
                        return "/educational-initiatives-higher-education"
                    case 3:
                        return "/lifelong-learning-and-workforce-training"
                    case 4:
                        return "/educational-research-quantum-technology"
                    case 5:
                        return "/equity-and-inclusion-qt-educational-initiatives"
                    default:
                        return ""
                }
            },
            wgTitle() {
                switch (this.selectedWG) {
                    case 1:
                        return "School education and Public Outreach"
                    case 2:
                        return "Higher Education"
                    case 3:
                        return "Lifelong Learning and (re)training"
                    case 4:
                        return "Transversal Education"
                    case 5:
                        return "Equity and inclusion"
                    default:
                        return ""
                }
            }
        },
        methods: {
            calcMembersTot() {
                console.log('calcMembersTot');
                fetchNodes('member', false).then(res => {
                    console.log('res', res.length);
                    this.totMembers = res.length
                })
            },
            max3(array) {
                var max = Object.entries(array)
                    .filter(a => a[0] != 'role_non_settato')
                    .sort((a, b) => {
                        return a[1] > b[1] ? -1 : 1
                    })

                //console.log(max)
                return max
            },
            toggleButtons() {
                this.showButtons = !this.showButtons
                //console.log(this.showButtons)
            },
            async selectWG(num) {
                this.selectedMembers = 0;
                if (this.selectedWG == num || num == 0) {
                    this.selectedWG = 0
                    this.selectedCode = 'total'
                    this.selectedName = 'Total'
                    await this.fetchMapData()
                } else {
                    this.selectedWG = num
                    this.selectedCode = 'wg'
                    this.selectedName = 'Total' //this.wgTitle(this.selectedWG)
                    await this.fetchSingleMapData(this.selectedWG)
                }

                this.toggleButtons()
                this.selectedCountry = ''
                this.selectedISO3 = ''
                if (this.map) {
                    this.map.removeLayer('countries-data')
                    this.colorCountries()
                }
            },
            async clearWG() {
                this.selectedWG = 0
                this.selectedCode = 'total'
                this.selectedName = 'Total'

                await this.fetchMapData()

                this.selectedCountry = ''
                this.selectedISO3 = ''
                if (this.map) {
                    this.map.removeLayer('countries-data')
                    this.colorCountries()
                }
            },
            colorCountries() {
                // Build a GL match expression that defines the color for every vector tile feature
                // Use the ISO 3166-1 alpha 3 code as the lookup key for the country shape
                const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];

                // Calculate color values for each country based on 'hdi' value
                var max = 0
                var keys = []
                if (this.selectedWG == 0)
                    keys = Object.entries(this.mapData.all_workgroups.country)
                else
                    keys = Object.entries(this.mapData.workgroup.country)

                for (const row of keys)
                    if (row[1] > max) max = row[1]

                for (const row of keys) {
                    // Convert the range of data values to a suitable color
                    const alpha = (row[1] / max) + 0.2;
                    let color = `rgba(220, 220, 220, ${alpha})`

                    matchExpression.push(row[0], color);
                }

                // Last value is the default, used where there is no data
                //matchExpression.push('rgb(255, 255, 255)');
                matchExpression.push(this.baseMapColor)

                // Add layer from the vector tile source to create the choropleth
                // Insert it below the 'admin-1-boundary-bg' layer in the style 
                this.map.addLayer({
                    'id': 'countries-data',
                    'type': 'fill',
                    'source': 'countries',
                    'source-layer': 'country_boundaries',
                    'paint': {
                        'fill-color': matchExpression
                    }
                }, 'state-stroke');
            },
            async fetchMapData() {
                if (!this.loadedWG.all) {
                    var url = new URL(process.env.VUE_APP_ENDPOINT + 'mapdata')

                    await fetch(url, {
                            method: 'POST'
                        })
                        .then(res => res.json())
                        .then(json => {
                            this.loadedWG.all = json.data
                            this.mapData = this.loadedWG.all
                            this.countryKeys = Object.entries(this.mapData.all_workgroups.country)
                            //console.log(this.mapData)
                        })

                } else {
                    this.mapData = this.loadedWG.all
                    this.countryKeys = Object.entries(this.mapData.all_workgroups.country)
                    //console.log('total data already loaded')
                }
            },
            async fetchSingleMapData(num) {
                if (!this.loadedWG['wg' + num]) {
                    var url = new URL(process.env.VUE_APP_ENDPOINT + 'mapdata')
                    url.search = new URLSearchParams({
                        'workgroup': num
                    }).toString()

                    await fetch(url, {
                            method: 'POST'
                        })
                        .then(res => res.json())
                        .then(json => {
                            this.loadedWG['wg' + num] = json.data
                            this.mapData = this.loadedWG['wg' + num]
                            this.countryKeys = Object.entries(this.mapData.workgroup.country)
                        })

                } else {
                    this.mapData = this.loadedWG['wg' + num]
                    this.countryKeys = Object.entries(this.mapData.workgroup.country)
                }
            },
            buildMap() {
                var vm = this

                if (this.map)
                    this.map.remove()

                this.map = new mapboxgl.Map({
                    container: this.$refs.map,
                    //style: 'mapbox://styles/interfase/ckylc2phv6pxw14qilb9kg0wp?fresh=true',
                    style: 'mapbox://styles/interfase/ckz5m7u5y001415qo3xjqm2q9?fresh=true',
                    zoom: 2.7,
                    minZoom: 2,
                    maxZoom: 7,
                    center: [6.5, 58]
                })

                this.map.scrollZoom.disable();
                const nav = new mapboxgl.NavigationControl();
                this.map.addControl(nav, 'bottom-left');

                this.map.on('load', () => {
                    this.map.addSource('countries', {
                        type: 'vector',
                        url: 'mapbox://mapbox.country-boundaries-v1'
                    });

                    this.map.addLayer({
                        'id': 'state-fills',
                        'type': 'fill',
                        'source': 'countries',
                        "source-layer": "country_boundaries",
                        'paint': {
                            'fill-color': vm.hoverMapColor,
                            'fill-opacity': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                1,
                                0
                            ]
                        }
                    }, 'country-labels');

                    this.map.addLayer({
                        'id': 'state-stroke',
                        'type': 'line',
                        'source': 'countries',
                        "source-layer": "country_boundaries",
                        'paint': {
                            'line-color': vm.darkMapColor,
                            'line-width': 0.3
                        }
                    }, 'state-fills');

                    this.map.addLayer({
                        'id': 'state-halo',
                        'type': 'line',
                        'source': 'countries',
                        "source-layer": "country_boundaries",
                        'paint': {
                            'line-color': 'rgb(180, 180, 180)',
                            'line-opacity': 0.5,
                            'line-width': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                1,
                                0
                            ]
                        }
                    }, 'country-labels');

                    this.colorCountries()

                })

                this.map.on('click', function (e) {
                    var point = vm.map.queryRenderedFeatures(e.point, {
                        layers: ['state-fills']
                    })[0]

                    //if it finds a country under hit point
                    if (point) {
                        //search it among possible countries

                        var found = false
                        vm.countryKeys.forEach(d => {
                            if (d[0] == point.properties.iso_3166_1_alpha_3) {
                                //and set the selection in the variables
                                //vm.selectedCode = 'country-total'
                                vm.selectedName = point.properties.name_en
                                vm.selectedCountry = point.properties.name_en
                                vm.selectedISO3 = point.properties.iso_3166_1_alpha_3
                                vm.selectedMembers = d[1]
                                found = true
                            }
                            if (vm.selectedCode == 'total')
                                vm.selectedCode = 'country-total'
                            if (vm.selectedCode == 'wg')
                                vm.selectedCode = 'country-wg'
                        })

                        if (!found) {
                            vm.selectedCountry = ''
                            vm.selectedISO3 = ''
                            //and set the total values back to parent scope
                            //based on Working Group selected (if any) or to entire QTEdu
                            if (vm.selectedWG == 0) {
                                vm.selectedCode = 'total'
                                vm.selectedName = 'Total'
                            } else {
                                vm.selectedCode = 'wg'
                                vm.selectedName = 'Total' //vm.wgTitle(vm.selectedWG)
                            }
                        }

                        //else unset the country selection
                    } else {
                        vm.selectedCountry = ''
                        vm.selectedISO3 = ''
                        //and set the total values back to parent scope
                        //based on Working Group selected (if any) or to entire QTEdu
                        if (vm.selectedWG == 0) {
                            vm.selectedCode = 'total'
                            vm.selectedName = 'Total'
                        } else {
                            vm.selectedCode = 'wg'
                            vm.selectedName = 'Total' //vm.wgTitle(vm.selectedWG)
                        }
                    }

                    //console.log(vm.selectedCode)

                })

                let hoveredStateId = null
                this.map.on('mousemove', function (e) {
                    var point = vm.map.queryRenderedFeatures(e.point, {
                        layers: ['state-fills']
                    })

                    if (hoveredStateId !== null) {
                        vm.map.setFeatureState({
                            source: 'countries',
                            sourceLayer: 'country_boundaries',
                            id: hoveredStateId
                        }, {
                            hover: false
                        });
                    }

                    if (point.length) {
                        let found = false
                        vm.countryKeys.forEach(d => {
                            if (d[0] == point[0].properties.iso_3166_1_alpha_3)
                                found = true
                        })

                        if (found) {
                            vm.displayHover = true
                            hoveredStateId = point[0].id
                            vm.map.setFeatureState({
                                source: 'countries',
                                sourceLayer: 'country_boundaries',
                                id: hoveredStateId
                            }, {
                                hover: true
                            })
                            vm.countryKeys.forEach(d => {
                                if (d[0] == point[0].properties.iso_3166_1_alpha_3) {
                                    vm.tooltip_top = e.point.y - 30
                                    vm.tooltip_left = e.point.x + 30
                                    vm.hoverMembers = d[1]
                                }
                            })

                        } else
                            vm.displayHover = false
                    } else
                        vm.displayHover = false

                });

                // When the mouse leaves the state-fill layer, update the feature state of the
                // previously hovered feature.
                this.map.on('mouseleave', 'state-fills', () => {
                    if (hoveredStateId !== null) {
                        this.map.setFeatureState({
                            source: 'countries',
                            sourceLayer: 'country_boundaries',
                            id: hoveredStateId
                        }, {
                            hover: false
                        });
                    }
                    hoveredStateId = null;
                });
            }
        },
        async mounted() {
            this.fetchSingleMapData(1)
            this.fetchSingleMapData(2)
            this.fetchSingleMapData(3)
            this.fetchSingleMapData(4)
            this.fetchSingleMapData(5)

            await this.fetchMapData()
            await this.buildMap()
            await this.calcMembersTot()
            this.selectedCode = 'total'
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styles/variables.scss';

    .container-disclaimer {
        margin: 0 15px;
        
        p {
            color: #FF0576;
            margin-bottom: 40px;
        }
    }

    @media (min-width:1024px){
        .container-disclaimer {
            display: none;
        }
    }

    .moving-tooltip {
        display: none;
    }


    .underlined {
        position: relative;

        &:after {
            background: url('../assets/underline_title_lightblue.svg');
            width: 80%;
            height: 1rem;
            bottom: -1.5rem;
            left: 20px;
            content: "";
            position: absolute;
            z-index: 0;
        }

        &:before {
            background: url('../assets/icon_rombo_shade.svg');
            width: 1rem;
            height: 1rem;
            bottom: -1.5rem;
            left: 10px;
            content: "";
            position: absolute;
            z-index: 1;
        }
    }

    .map {
        position: relative;
        height: 80vh;
        top: 0;
        bottom: 0;
        width: 100%;
    }

    ::v-deep .mapboxgl-ctrl-bottom-right {
        z-index: 5;
    }

    .btn.border.active {
        background-image: $gradient;

        span {
            -webkit-text-fill-color: #fff
        }
    }

    .pannello {
        position: relative;
        overflow: hidden;
    }

    .map-info {
        margin-top: 1rem;
        font-size: 2em;
        text-align: center;
        pointer-events: none;
    }

    .wg-map-info {
        padding: 0 1rem;
        width: 100%;

        .box {
            display: flex;
            flex-direction: row;
            justify-content: center;

            >p {
                margin: 0;
                font-size: 1rem;
                font-style: italic;
            }
        }
    }

    .box.first div {
        width: 50%;

        .num {
            margin: 0;
            font-size: 3rem;
            line-height: 1em;
            font-family: $primaryFont;
            color: $primaryColor;
            padding: 0 0 .5rem .5rem;
        }

        .didascalia {
            margin-top: .5em;
        }

        &.tot-members {
            .didascalia .country {
                font-size: 1.5rem;
            }

            .num {
                background: $gradient;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }

        .total-label {
            font-size: 1.5rem;
            color: #333;
            line-height: 25px;
            margin-top: 9px;
            display: block;

            &+.didascalia {
                margin-top: 0;
            }
        }
    }

    .box.second {
        border-bottom: dashed 2px rgba(#fff, .5);
        justify-content: space-between;

        div {
            width: 31%;

            p.icon-left {
                width: fit-content;
                margin: 10px auto 0px auto;
                font-family: $primaryFont;
                font-size: 2rem;
                padding-left: 2em;

                &:before {
                    width: 1.5rem;
                    height: 1.5rem;
                    padding-left: 1.5rem;
                }
            }
        }
    }

    .box.fourth {
        justify-content: space-between;

        div {
            width: 31%;

            p:first-child {
                font-family: $primaryFont;
                font-size: 2rem;
                margin-top: .5rem;
                margin-bottom: 0;
            }
        }
    }

    .box.fifth {
        a.btn {
            margin: 1em 0 1em auto;
            pointer-events: all;
        }
    }



    @media (min-width: 1024px) {

        .page-layout div.button-grid,
        .page-layout div.button-grid.fixed {
            top: 320px;
            border: 0;
            width: 600px;
            padding: 0;
            margin: 0;
            left: 54px;
        }

        .page-layout div.button-grid,
        .page-layout div.button-grid.fixed {
            position: absolute;
        }

        .moving-tooltip {
            display: flex;
            background-color: rgba($primaryColor, .7);
            height: 50px;
            width: 50px;
            border-radius: 50%;
            flex-direction: column;
            justify-content: center;

            h2 {
                color: #fff;
                width: 100%;
                height: 100%;
                margin-bottom: .75em;
                text-align: center;
            }
        }

        .map {
            height: 100vh;
        }

        .wg-map-info {
            width: 100%;
            padding: 1rem 0;

            .box {
                display: flex;
                position: relative;
                text-align: right;
                right: 2vw;
                padding-right: 1rem;

                div {
                    width: 100%;

                    .num {
                        margin: 0 0 0 auto;
                        font-size: 3rem;
                        color: #fff;
                    }

                    p {
                        width: fit-content;
                        margin: 1rem 0 0 auto;
                        color: #fff;
                    }
                }
            }

            .box.first {
                div.tot-members .didascalia .country {
                    font-size: 1.5rem;
                }

                .total-label {
                    font-size: 1.5rem;
                    margin-top: 11px;
                    line-height: 30px;
                    color: #fff;
                    margin-bottom: -1px;

                    &+.didascalia {
                        margin-top: 0em;
                    }
                }
            }
        }

        .map-info {
            width: 33.33%;
            height: 100vh;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 3;

            margin-top: 0;
            padding-top: 10vh;
            background: linear-gradient(to left, $primaryColor, transparent);
        }

        .box>p {
            color: rgba(#fff, .8);
        }

        .box.first {
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 2px dashed rgba(#fff, .5);
        }

        .box.second {
            padding-bottom: 1rem;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: .5em;
            margin-left: auto;

            p.icon-left {
                font-family: $primaryFont;
                font-size: 2rem;
                margin-right: 0;
            }
        }

        .box.third>p {
            text-align: right;
            margin: .5em 0 0 auto;
        }

        .box.fourth {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: .5em;
            margin-left: auto;

            p:first-child {
                margin-right: 1rem;
            }
        }


        .button-grid {
            .btn.contains {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    width: calc(100% + 6px);
                    height: calc(100% + 6px);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    padding: 6px;
                    border-radius: 50%;
                    background: $gradient border-box;
                    -webkit-mask:
                        linear-gradient(#fff 0 0) content-box,
                        linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                }
            }
        }

        .button-grid.hidden,
        .button-grid.fixed {
            height: 100%;
            pointer-events: none;
            position: absolute;
            bottom: unset;
            width: 100%;
            background-color: transparent;
            padding: 0;
            display: block;

            top: 360px;
            left: 29px;

            .read-more,
            .selected-wg {
                display: none;
            }

            .btn {
                display: block;
                width: 80px;
                min-width: unset;
                height: 80px;
                position: relative;
                pointer-events: all;
                padding: 0;
                border-radius: 50%;
                background: rgba(#fff, .2);
                border: 2px dashed #fff;

                &:hover,
                &.active {
                    border: 2px solid transparent;
                    background: $gradient padding-box, $gradient border-box;
                }

                &:nth-of-type(1) {
                    margin-bottom: -2.25rem;
                }

                &:nth-of-type(2) {
                    margin-bottom: -2.25rem;
                }

                &:nth-of-type(3) {
                    margin-bottom: -2.25rem;
                }

                &:nth-of-type(4) {
                    margin-bottom: -2.25rem;
                }

                &:nth-of-type(5) {
                    margin-bottom: -2.25rem;
                }

                &:nth-of-type(odd) {
                    left: 0px;
                }

                &:nth-of-type(even) {
                    left: 90px;
                }

                span {
                    display: none;
                }

                .wg-icon {
                    width: 100%;
                    height: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 30px;

                    &.target14 {
                        background-image: url(../assets/targets/icon_primary_small_white.svg);
                    }

                    &.target15 {
                        background-image: url(../assets/targets/icon_higher_small_white.svg);
                    }

                    &.target16 {
                        background-image: url(../assets/targets/icon_lifelong_small_white.svg);
                    }

                    &.target17 {
                        background-image: url(../assets/targets/icon_transversal_small_white.svg);
                    }

                    &.target18 {
                        background-image: url(../assets/targets/icon_equity_small_white.svg);
                    }
                }
            }
        }
    }


    @media (min-width:1024px) {
         .box.first{
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        
        .box.fourth,
        .box.second {
            p:last-child {
                font-size: 16px;
                line-height: 17px;
                margin-top: 11px;
            }
        }
    }    


    @media (min-width: 1200px) {
      .box.first{
            padding-bottom: 1rem;
            margin-bottom: 1rem;
        }

        .wg-map-info {
            margin-top: 5vh;
        }
    }


    @media (min-width: 1300px) {


        .page-layout div.button-grid,
        .page-layout div.button-grid.fixed {
            top: 400px;
            left: 54px;
        }

        .wg-map-info .box.first .total-label {
            line-height: 40px;
        }

        .map-info {
            width: 45%;
        }

        .wg-map-info {
            margin-top: 5vh;
            height: 85vh;
        }

        .wg-map-info .box div {
            &.tot-members .didascalia .country {
                font-size: 2rem;
                line-height: 2.5rem;
            }

            .num {
                font-size: 4rem;
            }
        }

        .box.second,
        .box.fourth {
            width: 60%;

            p.icon-left {
                font-size: 2.5rem;
            }
        }

        .box.first {
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            width: 60%;
            margin-left: auto;

            div {
                width: fit-content;
                margin-left: 2rem;
            }
        }

        .button-grid.fixed,
        .button-grid.hidden {
            position: absolute;
            width: 700px;
            left: 61px;
            top: 390px;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            top: 370px;
            left: 29px;
            width: 640px;


            &:before {
                content: 'Working Groups';
                position: absolute;
                color: #fff;
                font-size: 2em;
                top: -1.5em;
                left: 0;
                font-family: $primaryFont;
            }

            &:after {
                content: 'Filter by:';
                position: absolute;
                color: #aeb2ce;
                font-size: 16px;
                top: -68px;
                left: 0px;
                font-family: $primaryFont;
            }

            .btn {
                .wg-icon {
                    width: 100%;
                    height: 30px;
                    background-size: contain;
                }

                span {
                    display: block;
                    font-size: 12px;
                    margin-top: .5em;
                    color: #fff;
                    -webkit-text-fill-color: unset;
                    background-image: unset;
                    background-clip: unset
                }

                padding: 1em;
                width: 130px;
                height: 130px;
                margin-top: 1rem;

                &:nth-of-type(even) {
                    top: 130px;
                }

                &:nth-of-type(1) {
                    left: 0;
                }

                &:nth-of-type(2) {
                    left: -50px;
                }

                &:nth-of-type(3) {
                    left: -100px;
                }

                &:nth-of-type(4) {
                    left: -150px;
                }

                &:nth-of-type(5) {
                    left: -200px;
                }
            }
        }
    }
</style>