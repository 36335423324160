<template>
  <div class="page-layout" v-if="pageData && pageData.attributes">

    <!--<HeaderIntroImage :headerMetadata="pageData"></HeaderIntroImage>-->

    <Mapbox />
  </div>
</template>

<script>
import Mapbox from '../components/Mapbox.vue'
//import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
//import { fetchSinglePage } from '../libs/drupalClient'
export default {
  name: 'about',
  components: {
    //HeaderIntroImage,
    Mapbox
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "QTEDu European Community Map",
          tag: "",
          subtitle: ""
        }
      },
      currentPageID: ""
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    }
  },
  methods: {},
  mounted() {},
  watch: {}
}
</script>

<style lang="scss" scoped>
</style>